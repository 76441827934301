import { useFallbackStorageValue } from "@product/scmp-sdk";
import { useAsync } from "@react-hookz/web";
import axios from "axios";
import { useAtomValue } from "jotai";
import qs from "qs";

import type {
  OnboardQueryStringPayload,
  OnboardUserType,
} from "scmp-app/components/popup-container/onboarding/types";
import { getGoogleAnalyticsClientId } from "scmp-app/components/tracking/google-analytics/apis";
import { config } from "scmp-app/data";
import { accountAtom } from "scmp-app/lib/account";
import { rosettaAtom } from "scmp-app/lib/rosetta";

import { useParsedCookie } from "./cookie";

// https://docs.google.com/spreadsheets/d/1ECKbfqOFH3CXq-F0_H8ym3r0uDrzZr9sPN4tqnWYDJ4/edit#gid=340627509
export type UserType =
  | "008"
  | "014"
  | "016"
  | "017"
  | "018"
  | "028"
  | "029"
  | "030"
  | "038"
  | "057"
  | "058"
  | "083"
  | "084"
  | "amp"
  | "default"
  | "myNews"
  | "newsletter"
  | "subscribers";

type FetchUserTypes = {
  id: UserType;
  name: string;
};

type FetchUserTypeResponse = {
  data: {
    user_types: FetchUserTypes[];
  } | null;
};

export const useFetchUserTypes = () => {
  const { value: overWrittenGAClientId } = useFallbackStorageValue<string>(
    "qaTestOverwriteGAClientId",
  );
  const [state, actions] = useAsync(async () => {
    try {
      const gaClientId =
        config.general.env !== "production" && overWrittenGAClientId
          ? overWrittenGAClientId
          : ((await getGoogleAnalyticsClientId()) as string);

      if (!gaClientId) return [];

      const url = new URL(`${config.general.userTypeHost}/client-id/${gaClientId}`);

      const response = await axios.get<FetchUserTypeResponse>(url.toString(), {
        withCredentials: true,
      });

      return response?.data.data?.user_types ?? [];
    } catch {
      return [];
    }
  });

  return {
    fetchUserTypes: actions.execute,
    userTypes: state.result ?? [],
  };
};

export const useGetUserType = () => {
  const { value: forcedQaUserType } = useParsedCookie("qa_user_type", raw => raw, {
    initializeWithValue: true,
  });

  const parsedQueryString = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  }) as unknown as OnboardQueryStringPayload;
  const isFinishedMyNews = parsedQueryString.finishedMyNews === "true";

  const { user } = useAtomValue(accountAtom);
  const isAmp = user?.source.includes("amp");

  const asyncRosettaState = useAtomValue(rosettaAtom);
  const isScmpSubscriber = asyncRosettaState?.result?.isScmpSubscriber ?? false;

  const getUserType = (fetchedUserTypes: FetchUserTypes[]) => {
    if (config.general.env !== "production" && forcedQaUserType)
      return forcedQaUserType as OnboardUserType;

    if (isScmpSubscriber) return "subscribers";
    if (isFinishedMyNews) return "myNews";
    if (isAmp) return "amp";

    if (
      parsedQueryString.firstTimeRegister === "true" &&
      parsedQueryString.signUpComplete === "true"
    ) {
      return "newsletter";
    }

    const userTypeIds = fetchedUserTypes.map(element => element.id);
    if (userTypeIds.length === 0) return "default";

    const userType = getUserTypeFromList(userTypeIds);
    return userType;
  };

  return { getUserType };
};

// NOTE: the order in which these user type appears is important and has been sorted by order of
//       importance
const getUserTypeFromList = (userTypeList: string[]): UserType => {
  const userType = (() => {
    if (userTypeList.includes("018")) return "018";
    if (userTypeList.includes("028")) return "028";
    if (userTypeList.includes("029")) return "029";
    if (userTypeList.includes("038")) return "038";
    if (userTypeList.includes("083")) return "083";
    if (userTypeList.includes("084")) return "084";
    if (userTypeList.includes("058")) return "058";
    if (userTypeList.includes("008")) return "008";
    if (userTypeList.includes("057")) return "057";
    if (userTypeList.includes("016")) return "016";
    if (userTypeList.includes("017")) return "017";
    if (userTypeList.includes("030")) return "030";
    if (userTypeList.includes("014")) return "014";
    return "default";
  })();
  return userType;
};
