import { atom } from "jotai";

export interface GDPR {
  isAccepted: boolean;
  isNextSession: boolean;
}

export const gdprAtom = atom<GDPR>({
  isAccepted: false,
  isNextSession: false,
});
