import { SdkContextProvider as ContextProvider } from "@product/scmp-sdk";
import type { FunctionComponent, ReactNode } from "react";
import { useRelayEnvironment } from "react-relay";

import { useScmpSdk } from "./hooks";

export type Props = {
  children?: ReactNode;
};

export const SdkContextProvider: FunctionComponent<Props> = ({ children }) => {
  const environment = useRelayEnvironment();
  const context = useScmpSdk(environment);
  return <ContextProvider value={context}>{children}</ContextProvider>;
};

SdkContextProvider.displayName = "SdkContextProvider";
